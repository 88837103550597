import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
export const query = graphql`
  query parasite {
    images: allFile(
      filter: { relativeDirectory: { eq: "parasite" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 270)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Parasite 2019, dir. Boon Joon Ho",
  "date": "2019-12-15T00:00:00.000Z"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    }))} mdxType="Gallery" />
    <p>{`First, I watched `}<a parentName="p" {...{
        "href": "https://www.rottentomatoes.com/m/oldboy"
      }}>{`Oldboy 2003`}</a>{` for Park Chan-wook, I find it quite mind blowing to watch a story like this.
I thought I will never watch something like this again, but, I found more and more from Korean cinema. I don't know exactly how to describe this kind of films,
the writers starting the film in a low base, at the middle ,or sometimes, end of the film you got shocked! I think Korean directors are very skilled in creating mind blowing, unexpected, and visual moments.
They have good stories and always have that intelligent specific details to tell you about. From one year I watched `}<a parentName="p" {...{
        "href": "https://www.rottentomatoes.com/m/sympathy_for_lady_vengeance"
      }}>{`Sympathy For Lady Vengeance`}</a>{`
directed by Park Chan-wook I found it quite normal not as the same level of Oldboy but there are a good story and details.
Watching Parasite is an experience that you will never find in any Korean film. I will not dig into the film details you can do it
now by yourself, but what I can say about this film is purely social. For me, the film depicts two major social definitions.
First and it's obvious: discrimination. This can be found in frames transition between two worlds a world full of cloths and other of stink wet ones.
Second and it's the darkest part in the film is "it's not about plans, we are going downstairs". Poverty is minimizing person's sake in life to a level that this person can be a parasite.
What I think, if we don't find a solution for poverty, all we are doing is slowly dying. We are going downstairs. It's just becoming more worse.
This can be depicted in the film when those poor people were running away from a something terrible happened and what they are doing is just going downstairs, a basement below a basement.
It's so deep. You are facing nothing except dead ends! and this is what poverty can do.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      